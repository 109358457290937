import { Injectable } from '@angular/core';
import {publicIp, publicIpv4, publicIpv6} from 'public-ip';
import  clevertap from 'clevertap-web-sdk'

@Injectable({
  providedIn: 'root'
})
export class StoreDataServiceService {

  public ipAddress: string;
  public selectedUrl: string;
  public _5paisacookie: string;
  public jwtToken : string;
  public aspxAuth :string;
  public solaceCred : string;
  public firebasePopupEnable : boolean;
  public apiDelayValue : number
  public apiTimeoutValue : number
  public theme : string;
  public isDefaultThemeChanged :string ='N';
  public isCallApi : string =''; // Y = Call Api, N = Not Call Api
  public tradestationredirectionOn : string =''; // 'OT' = Old tradestation redirection, 'NT' = New tradestation redirection
  public isCaptchaEnabled : string ='';
  public tradestationUrl : string = '';
  public isDormantAccount : string ='';
  public tradestationNewUrl : string = '';
  public swarajauthSecretKey : string = '';
  // fetchData(): Promise<string> {
  //   return new Promise<string>(async (resolve, reject) => {
  //     resolve(await publicIpv4());
  //   });
  // }
  fetchData(): Promise<string> {
    return new Promise<string>(async (resolve, reject) => {
      try {
        const ip = await publicIpv4();
        resolve(ip);
      } catch (error) {
        if (error.response && error.response.status === 403) {
          console.log('IpNotFoundError: Access forbidden. Could not get the public IP address')
          reject('10.254.0.195'); // static IP address
        } else {
          console.log(' public IP address')
          reject('10.254.0.195'); // static IP address
        }
      }
    });
  }
  setIpAddress(ipValue){
    this.ipAddress = ipValue;
  }
  getIpAddress(): string{
    return this.ipAddress;
  }
  setSelectedUrl(urlString: string){
    this.selectedUrl = urlString;
  }
  getSelectedUrl(): string{
    return this.selectedUrl;
  }
  set5paisaCookie(cookie: string){
    this._5paisacookie = cookie;
  }
  setJwtToken(token : string){
    this.jwtToken = token;
  }
  setAspxAuthCookie (aspxauth : string){
    this.aspxAuth = aspxauth;
  }
  setSolaceCred(SolaceCred : string){
    this.solaceCred = SolaceCred;
  }
  setFirebasePopupConfigration(isFirebasePopupEnabled : boolean){
    this.firebasePopupEnable = isFirebasePopupEnabled;
  }
  getFirebasePopupConfigration(): boolean{
    return this.firebasePopupEnable;
  }
  clevertapCommonFunction(eventName, property) { 
    try{
        clevertap.event.push(eventName, property);
    }
    catch(e){
      console.log(e,"clevertapPush");
    }
   
  }
  setDelayApiResponseTimer(apiDelayValueParam : string){
    this.apiDelayValue =  Number(apiDelayValueParam);
  }
  getDelayApiResponseTime(): number{
    return this.apiDelayValue;
  }

  setApiTimeoutFirebaseConfigration(apiTimeoutValue : string){
    this.apiTimeoutValue = Number(apiTimeoutValue);
  }
  getApiTimeoutFirebaseConfigration(): number{
    return this.apiTimeoutValue;
  }
  setUser_Themes(theme : string){
    this.theme = theme;
  }
  getUser_Themes(){
    return this.theme;
  }
  defafulThemeChange(prompt : string){
    this.isDefaultThemeChanged = prompt;
  }
  getdefafulThemeChange(){
    return this.isDefaultThemeChanged;
  }
  userDetails =[
    {
      'clientCode'   :null,
      'userName'     :null,
      'userEmailId'  :null,
      'userMobileNo' :null,
      'clientStatus' :null,
      'deviceBinded' :null,
      'redirectionType' :null,
    }
  ]
  setNeedtoCallTradestationBetaApi(isCalltoApi : string){
    this.isCallApi = isCalltoApi;
  }
  getNeedtoCallTradestationBetaApi(){
    return this.isCallApi;
  }
  setTradestationRedirectionValue(tradestationRedirection : string){
    this.tradestationredirectionOn = tradestationRedirection;
  }
  getTradestationRedirectionValue(){
    return this.tradestationredirectionOn;
  }
  setCaptchaEnabled(captchaEnabled : string){
    this.isCaptchaEnabled = captchaEnabled;
  }
  getCaptchaEnabled(): string{
    return this.isCaptchaEnabled;
  }
  setdefaultTradestationUrl(url){
    this.tradestationUrl = url;
  }
  setdefaultNewTradestationUrl(url){
    this.tradestationNewUrl = url;
  }
  setSwarajauthSecretKey(secretKey){
    this.swarajauthSecretKey = secretKey;
  }
  
}
