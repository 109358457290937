import { Component, ElementRef, OnInit, Renderer2 ,NgZone} from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { StoreDataServiceService } from './store-data-service.service';
import { ActivatedRoute, Params } from '@angular/router';
import { AngularFireRemoteConfig } from '@angular/fire/compat/remote-config';
import { initializeApp } from 'firebase/app';
import { getRemoteConfig } from 'firebase/remote-config';
import { environment } from 'src/environments/environment';
import { UtilService } from 'src/app/service/util.service';
import {
  RemoteConfig,
  fetchAndActivate,
  getValue,
} from "firebase/remote-config";
import { ConstantService } from './service/constant.service';
import { EncryptionService } from './service/encryption.service';
import { provideAnimations } from '@angular/platform-browser/animations';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    autoplay: true,
    autoplayTimeout: 4000,
    autoplayHoverPause: true,
    
    //navText: ['&#8249', '&#8250;'],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      760: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
    nav: false
  };
  IpAddress = '';
  themes :string ='';
  returnUrl :string ='';
  isChecked : boolean = true;
  title = '5P_Insta_Rewards';
  showLoader = false;
   patterns = [
    '/SignOn/Auth',
    
    '/login-customersupport',
    '/error',
    '/lwp',
    '/dealer'
   ];
   myCurrentURL : string = '';
   patternRedirectionURL:string = '';
  domainUrl : string;
  hostOrigin : string;
  pathname : string ;
  queryParams : string ;
 
  //public IpAddress : any;
  constructor(
    private storeDataServiceService : StoreDataServiceService,
    private renderer: Renderer2,
    private el: ElementRef,
    private activatedRouter: ActivatedRoute,
    private remoteConfig: AngularFireRemoteConfig,
    public constantService: ConstantService,
    private utilService: UtilService,
    private encryptionService: EncryptionService,
    private zone: NgZone,
    private toastr: ToastrService)
    {
      this.activatedRouter.queryParams.subscribe((params: Params) => {
        // Access the query parameters here
        let queryParamstheme = 'light'; //params['theme']; // remove theme said by gaurav 24-08-2024
        if(queryParamstheme != undefined){
          queryParamstheme == 'dark' ? queryParamstheme = 'dark' : queryParamstheme = 'light';
          this.themes = queryParamstheme;
          this.storeDataServiceService.setUser_Themes(this.themes);
          this.isChecked = true;
          this.toggleDarkMode();
        }
        else{
          let storedtheme = this.storeDataServiceService.getUser_Themes();
          if(storedtheme != undefined){
            this.themes = storedtheme ;
          }
          else{
            this.themes = this.detectSystemTheme(); // take System theme;
          }
          this.isChecked = true;
          this.storeDataServiceService.setUser_Themes(this.themes);
          this.toggleDarkMode();
        }
        
      });
      
    }

  
  async ngOnInit() : Promise<void>  {
    this.showLoader = true;   
     this.initializeFireBaseAppAndRemoteConfig().then(() => {
      
     }).finally(() => {
     
      // this.storeDataServiceService.fetchData()
      //   .then((result) => {
      //     this.IpAddress = result;
      //     this.storeDataServiceService.setIpAddress(this.IpAddress);
      //   })
      //   .catch((error) => {
      //     console.log('error',error)
         
      //   }).finally(() => {
      //     this.showLoader = false;
      //   });
     }); 
     this.storeDataServiceService.fetchData()
      .then((result) => {
        this.IpAddress = result;
        this.storeDataServiceService.setIpAddress(this.IpAddress);
      }).finally(() => {
        this.showLoader = false;
      })
      .catch((error) => {
        this.showLoader = false;
        console.log('error',error)
       
      });
   

  }
  toggleDarkMode() {
     if(this.themes == 'light' && this.isChecked ==true){
       this.renderer.removeClass(document.body, 'dark-mode');
       this.storeDataServiceService.setUser_Themes(this.themes);
       this.isChecked =false;
       this.themes ='dark';
    }
    else if(this.themes == 'dark'){
      this.renderer.addClass(document.body, 'dark-mode');
      this.storeDataServiceService.setUser_Themes(this.themes);
      this.isChecked =true;
      this.themes ='light';
    }
    else{
      //this.themes ='dark';
      this.isChecked =false;
      this.storeDataServiceService.setUser_Themes(this.themes);
      this.renderer.removeClass(document.body, 'dark-mode');
      
    }
   
   
  }
  isChangedtheme(): void{
    this.storeDataServiceService.defafulThemeChange('Y');
    this.toggleDarkMode();
  }
   initializeFireBaseAppAndRemoteConfig =  async() =>{
    try {
      
      const app = initializeApp(environment.firebase);
      const remoteConfig: RemoteConfig = getRemoteConfig(app);
      remoteConfig.settings.minimumFetchIntervalMillis = 60000;
  
      
      await fetchAndActivate(remoteConfig);
      const frontend_crossSell_keys = getValue(remoteConfig, "frontend_crossSell_keys").asString();
      const web_tradestation_url = getValue(remoteConfig, "web_tradestation_url").asString();
      let remoteConfigData , webremoteConfigData;
      try {
        remoteConfigData = JSON.parse(frontend_crossSell_keys);
        webremoteConfigData = JSON.parse(web_tradestation_url);
        this.storeDataServiceService.setFirebasePopupConfigration(remoteConfigData.isPopupEnabled); // this value in global service
        this.storeDataServiceService.setDelayApiResponseTimer(remoteConfigData.APIDelayValue); // this value in global service
        this.storeDataServiceService.setApiTimeoutFirebaseConfigration(remoteConfigData.APITimeoutValue);
        this.myCurrentURL = remoteConfigData.myCurrentURL.toLowerCase();
        this.patternRedirectionURL = remoteConfigData.patternRedirectionURL.toLowerCase();
        this.storeDataServiceService.setCaptchaEnabled(remoteConfigData.isCaptchaEnabled); //isCaptchaEnabledPreProd
        this.storeDataServiceService.setNeedtoCallTradestationBetaApi(remoteConfigData.tradestationRedirectionThroughAPI); // 'Y'
        this.storeDataServiceService.setTradestationRedirectionValue(remoteConfigData.tradestationRedirectionValue); // 'OT & NT'
        this.storeDataServiceService.setdefaultTradestationUrl(webremoteConfigData.url);
        this.storeDataServiceService.setdefaultNewTradestationUrl(webremoteConfigData.newUrl);
        this.storeDataServiceService.setSwarajauthSecretKey(remoteConfigData.swarajauthProductionSecretKey);
      } catch (error) {
        console.error("Error parsing remote config data", error);
        this.setDefaultConfig();
      }
      
    } catch (err) {
      console.error("Error initializing Firebase", err);
      this.setDefaultConfig();
    } finally {
    
    }
  };
  
 
  detectSystemTheme(): string{
    let systemTheme=''
    try{
      if (window.matchMedia) {
        // Check if the dark-mode Media-Query matches
        if(window.matchMedia('(prefers-color-scheme: dark)').matches){
          return systemTheme = 'dark';
        } else {
          return systemTheme = 'light';
        }
      } else {
        return systemTheme = 'light';
      }
    }
    catch(e){
      return systemTheme = 'light';
    }
  }
 
  setDefaultConfig() {
    this.storeDataServiceService.setFirebasePopupConfigration(true); // if error then set default value true
    this.myCurrentURL = 'https://weblogin.5paisa.com';
    this.patternRedirectionURL = 'https://login.5paisa.com';
    this.storeDataServiceService.setCaptchaEnabled('N'); // if error then set default value false
  }
  
}
